import { FormControl } from './ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  preventTouchStart,
} from './ui/select'

type Props = {
  value: string | null
  onChange: (value: string | null) => void
}

export const SweaterSizeSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      onValueChange={(v) => onChange(v === '0' ? null : v)}
      defaultValue={value ?? '0'}
    >
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent ref={preventTouchStart}>
        <SelectItem value="0">Nei takk</SelectItem>
        <SelectItem value="xs">XS</SelectItem>
        <SelectItem value="s">S</SelectItem>
        <SelectItem value="m">M</SelectItem>
        <SelectItem value="l">L</SelectItem>
        <SelectItem value="xl">XL</SelectItem>
      </SelectContent>
    </Select>
  )
}
