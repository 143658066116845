import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { toast } from '@/components/ui/use-toast'
import { BottomNavbar } from '@/components/bottom-navbar'
import { ContactHeader } from '../components/contact-section-header'
import { OrderHeader } from '../components/order-header'
import {
  EditContactPersonForm,
  FormValues,
} from '../components/edit-contact-person-form'

export const contactPersonFragment = graphql(`
  fragment UpdateContactPersonData on ContactPerson {
    id
    firstName
    lastName
    email
    phone
  }
`)

export const updateContactPersonDataDocument = graphql(`
  query UpdateContactPersonData($orderId: ID!) {
    order(id: $orderId) {
        id
        contactPersons {
          id
          ...UpdateContactPersonData
        }
      }
  }
`)

const updateContactPersonDocument = graphql(`
  mutation UpdateContactPerson($id: ID!, $input: UpdateContactPersonInput!) {
    updateContactPerson(id: $id, input: $input) {
      contactPerson {
        id
        ...UpdateContactPersonData
      }
    }
  }
`)

export const EditContactPerson = () => {
  const params = useParams<'orderId'>()
  const navigate = useNavigate()
  const [{ data, fetching, error }] = useQuery({
    query: updateContactPersonDataDocument,
    variables: { orderId: params.orderId! },
  })
  const [{ fetching: submitting }, updateContactPerson] = useMutation(
    updateContactPersonDocument
  )

  const onSubmit = async (input: FormValues) => {
    if (!data) return
    const { error } = await updateContactPerson({
      id: data.order.contactPersons[0].id,
      input,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kontaktperson er oppdatert',
      variant: 'default',
    })
    navigate('../')
  }

  return (
    <div className="flex flex-col gap-4">
      <OrderHeader />
      <ContactHeader />
      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {data && (
        <EditContactPersonForm
          defaultValues={data.order.contactPersons[0]}
          onSubmit={onSubmit}
        />
      )}
      <BottomNavbar className="justify-between">
        <>
          <Button variant="ghost" onClick={() => navigate('../')}>
            Avbryt
          </Button>
          <Button form="edit-contact-person-form" disabled={submitting}>
            Lagre
          </Button>
        </>
      </BottomNavbar>
    </div>
  )
}
