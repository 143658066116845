import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { Content } from '@/components/layout'
import { BottomNavbar } from '@/components/bottom-navbar'
import { BookingsForm, FormValues } from './components/bookings-form'
import { useOrderContext } from './components/order-context'
import { ExpiryNotice } from './components/expiry-notice'

export const Bookings = () => {
  const { state, dispatch } = useOrderContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (state.bookings.length === 0) {
      navigate('..')
    }
  }, [])

  const onSubmit = async (input: FormValues) => {
    dispatch({ type: 'setBookings', data: input.bookings }, () => {
      navigate('../contacts')
    })
  }

  return (
    <>
      <ExpiryNotice />
      <Content>
        <BookingsForm
          onSubmit={onSubmit}
          defaultValues={{ bookings: state.bookings }}
        />
        <BottomNavbar className="justify-between">
          <Button asChild variant="ghost">
            <Link to="..">Tilbake</Link>
          </Button>
          <Button type="submit" form="bookings-form">
            Til foresatt
          </Button>
        </BottomNavbar>
      </Content>
    </>
  )
}
