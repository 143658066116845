import { FormControl } from './ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  preventTouchStart,
} from './ui/select'

type Props = {
  value: number
  onChange: (value: number) => void
}

export const PastVisitsSelect = ({ value, onChange }: Props) => {
  return (
    <Select onValueChange={(v) => onChange(Number(v))} value={String(value)}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent ref={preventTouchStart}>
        {Array.from({ length: 11 }, (_, i) => (
          <SelectItem key={String(i)} value={String(i)}>
            {i === 0 ? 'Nei' : `Ja, ${i} år`}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
