import { Client, fetchExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { config } from '@/config'

type UrqlClientOptions = {
  fetch?: WindowOrWorkerGlobalScope['fetch']
}

export function createUrqlClient(options: UrqlClientOptions = {}) {
  return new Client({
    url: `${config.apiUrl}/graphql`,
    requestPolicy: 'network-only',
    fetch: options.fetch,
    fetchOptions: {
      credentials: 'include',
    },
    exchanges: [
      cacheExchange({
        keys: {
          Address: () => null,
          PaymentData: () => null,
        },
      }),
      fetchExchange,
    ],
  })
}
