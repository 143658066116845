import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { toast } from '@/components/ui/use-toast'
import { BottomNavbar } from '@/components/bottom-navbar'
import { ContactHeader } from '../components/contact-section-header'
import { OrderHeader } from '../components/order-header'
import { EditCustomerForm, FormValues } from '../components/edit-customer-form'

export const customerFragment = graphql(`
  fragment UpdateCustomerData on Customer {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      postalCode
      postalArea
    }
    postAddress {
      streetAddress
      postalCode
      postalArea
    }
  }
`)

export const updateCustomerDataDocument = graphql(`
  query UpdateCustomerData($orderId: ID!, $signature: String!) {
    order(id: $orderId, signature: $signature) {
      id
      customer {
        id
        ...UpdateCustomerData
      }
    }
  }
`)

const updateCustomerDocument = graphql(`
  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!, $signature: String!) {
    updateCustomer(orderId: $orderId, input: $input, signature: $signature) {
      customer {
        id
        ...UpdateCustomerData
      }
    }
  }
`)

export const EditCustomer = () => {
  const params = useParams<'orderId' | 'signature'>()
  const navigate = useNavigate()
  const [{ data, fetching, error }] = useQuery({
    query: updateCustomerDataDocument,
    variables: { orderId: params.orderId!, signature: params.signature! },
  })
  const [{ fetching: submitting }, updateCustomer] = useMutation(
    updateCustomerDocument
  )

  const onSubmit = async (input: FormValues) => {
    if (!data) return
    const { customPostAddress, postAddress, ...rest } = input
    const { error } = await updateCustomer({
      orderId: data.order.id,
      input: {
        ...rest,
        postAddress: customPostAddress ? postAddress : null,
      },
      signature: params.signature!,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kontaktperson er oppdatert',
      variant: 'default',
    })
    navigate('../')
  }

  return (
    <div className="flex flex-col gap-4">
      <OrderHeader />
      <ContactHeader />
      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {data && (
        <EditCustomerForm
          defaultValues={{
            ...data.order.customer,
            customPostAddress: !!data.order.customer.postAddress,
            postAddress: data.order.customer.postAddress || {
              streetAddress: '',
              postalCode: '',
              postalArea: '',
            },
          }}
          onSubmit={onSubmit}
        />
      )}
      <BottomNavbar className="justify-between">
        <>
          <Button variant="ghost" onClick={() => navigate('../')}>
            Avbryt
          </Button>
          <Button form="customer-form" disabled={submitting}>
            Lagre
          </Button>
        </>
      </BottomNavbar>
    </div>
  )
}
