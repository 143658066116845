export function groupBy<T = Record<string, unknown>>(
  list: T[],
  iterator: (item: T) => string
) {
  return list.reduce(
    (acc, item) => {
      const key = iterator(item)
      if (acc[key]) {
        acc[key].push(item)
      } else {
        acc[key] = [item]
      }
      return acc
    },
    {} as { [k: string]: T[] }
  )
}
