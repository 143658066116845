import { intervalToDuration } from 'date-fns'
import { graphql } from '@/__generated__/gql'
import { TermCardFragment } from '@/__generated__/gql/graphql'
import { cn } from '@/utils/cn'
import { formatCurrency } from '@/utils/format-currency'
import { formatDate } from '@/utils/format-date'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

type Props = {
  term: TermCardFragment
  isAccepted: boolean
  isWaitingList: boolean
  children?: React.ReactNode
  className?: string
}

export const termCardDocument = graphql(`
  fragment TermCard on Term {
    id
    name
    price
    startsAt
    endsAt
    isFullyBooked
  }
`)

export const TermCard = ({
  term,
  isAccepted,
  isWaitingList,
  className,
  children,
}: Props) => {
  const interval = intervalToDuration({
    start: new Date(term.startsAt),
    end: new Date(term.endsAt),
  })

  const numberOfDays =
    (interval.days ?? 0) + Math.ceil((interval.hours ?? 0) / 24) + 1

  const status = isAccepted
    ? 'Fått plass'
    : isWaitingList
      ? 'På venteliste'
      : term.isFullyBooked
        ? 'Venteliste'
        : 'Ledig plass'

  return (
    <Card
      className={cn(
        isAccepted && 'bg-light-blue-2',
        isWaitingList && 'bg-light-purple',
        className
      )}
    >
      <CardHeader>
        <CardTitle>
          <div className="flex justify-between font-medium">
            <span>{term.name}</span>
            <span>{formatCurrency(term.price)}</span>
          </div>
        </CardTitle>
        <CardDescription>
          <div className="flex justify-between mt-1">
            <span>
              {formatDate(term.startsAt)} - {formatDate(term.endsAt)}
            </span>
            <span>{numberOfDays} dager</span>
          </div>
          <div className="flex justify-between mt-1">
            <span>Status</span>
            <span>{status}</span>
          </div>
        </CardDescription>
      </CardHeader>

      {!!children && <CardContent>{children}</CardContent>}
    </Card>
  )
}
