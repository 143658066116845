import { cn } from '@/utils/cn'

type Props = {
  children: React.ReactNode
  className?: string
  transition?: string
}

export const BottomNavbar = ({ children, className, transition }: Props) => {
  return (
    <div
      className={`fixed bottom-0 left-0 right-0 flex bg-white h-17 py-4 shadow-[0px_3px_10px_0px_rgba(0,0,0,0.25)] transition-transform duration-500 ease-in-out transform ${transition}`}
    >
      <div className={cn('container max-w-md px-6 flex', className)}>
        {children}
      </div>
    </div>
  )
}
