import React from 'react'
import { useParams } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { useMutation } from 'urql'
import { TrashIcon } from '@radix-ui/react-icons'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

export const deleteContactPersonDocument = graphql(`
  mutation DeleteContactPerson($id: ID!, $signature: String) {
    deleteContactPerson(id: $id, signature: $signature) {
      order {
        id
        contactPersons {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`)

export const DeleteContactPersonButton = ({
  contactPersonId,
}: { contactPersonId: string }) => {
  const params = useParams<'signature'>()
  const [open, setOpen] = React.useState(false)
  const [_, deleteContactPerson] = useMutation(deleteContactPersonDocument)
  const { toast } = useToast()

  const onDeletingContactPerson = async () => {
    const { error } = await deleteContactPerson({
      id: contactPersonId,
      signature: params.signature,
    })

    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Slettet!',
      description: 'Kontaktperson er slettet.',
      variant: 'default',
    })
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="link"
          className="flex justify-start items-center text-link-blue font-semibold text-sm pl-0"
        >
          <TrashIcon className="w-6 h-6 mr-2" />
          Slett kontaktperson
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Slett kontaktperson</DialogTitle>
          <DialogDescription>
            Er du sikker på at du vil slette denne kontaktpersonen?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Avbryt
            </Button>
          </DialogClose>
          <Button type="submit" onClick={onDeletingContactPerson}>
            Slett
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
