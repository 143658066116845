import { PersonIcon } from '@radix-ui/react-icons'

export const ContactHeader = () => {
  return (
    <div className="flex justify-center items-center text-xl mb-4 font-semibold">
      <PersonIcon className="w-6 h-6 mr-1" />
      Kontakt
    </div>
  )
}
