import { Link } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { CustomerFragment } from '@/__generated__/gql/graphql'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Dd, Dl, Dt } from '@/components/ui/dl'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'

export const customerDocument = graphql(`
  fragment Customer on Customer {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      postalCode
      postalArea
    }
    postAddress {
      streetAddress
      postalCode
      postalArea
    }
  }
`)

export const CustomerCard = ({ customer }: { customer: CustomerFragment }) => {
  return (
    <Card>
      <CardContent className="pb-2">
        <AccordionItem value="customer">
          <AccordionTrigger className="text-base">
            {`${customer.firstName} ${customer.lastName}`}
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col gap-4">
              <Dl>
                <Dt>Telefon</Dt>
                <Dd>{customer.phone}</Dd>
              </Dl>
              <Dl>
                <Dt>Epost</Dt>
                <Dd>{customer.email}</Dd>
              </Dl>
              <Dl>
                <Dt>Adresse</Dt>
                <Dd>
                  {customer.address.streetAddress},{' '}
                  {customer.address.postalCode} {customer.address.postalArea}
                </Dd>
              </Dl>
              {customer.postAddress && (
                <Dl>
                  <Dt>Postadresse</Dt>
                  <Dd>
                    {customer.postAddress.streetAddress},{' '}
                    {customer.postAddress.postalCode}{' '}
                    {customer.postAddress.postalArea}
                  </Dd>
                </Dl>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </CardContent>
      <CardFooter className="justify-end">
        <Link
          to={`./edit-customer/${customer.id}`}
          className="flex justify-start items-center font-semibold text-sm"
        >
          <Pencil1Icon className="w-6 h-6 mr-2" />
          Rediger
        </Link>
      </CardFooter>
    </Card>
  )
}
