import * as z from 'zod'
import { Gender, SweaterSize } from '@/__generated__/gql/graphql'

export const schemas = {
  firstName: z.string().min(2, 'Fornavn må fylles ut'),
  lastName: z.string().min(2, 'Etternavn må fylles ut'),
  dob: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, 'Fødselsdato må fylles ut'),
  gender: z.nativeEnum(Gender, {
    errorMap: () => ({ message: 'Kjønn må fylles ut' }),
  }),
  pastVisits: z.number(),
  friends: z.string(),
  notes: z.string(),
  sweaterSize: z.nativeEnum(SweaterSize).nullable(),
  creditLimit: z.number().nullable(),
}
