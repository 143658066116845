import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { TargetGroup } from '@/__generated__/gql/graphql'
import { groupBy } from '@/utils/group-by'
import { formatCurrency } from '@/utils/format-currency'
import { Content } from '@/components/layout'
import { Button } from '@/components/ui/button'
import { TargetGroupHeading } from '@/components/target-group-heading'
import { BottomNavbar } from '@/components/bottom-navbar'
import { MainHeading } from '@/components/main-heading'
import { useOrderContext } from './components/order-context'
import { NoTermsAvailable } from './no-terms-available'
import { ExpiryNotice } from './components/expiry-notice'
import { NewOrderTermCard } from './components/new-order-term-card'
import { ReserveTerm } from './components/reserve-term'
import { InfoNotice } from './components/info-notice'

export const Terms = () => {
  const navigate = useNavigate()
  const { state, dispatch, availableTerms } = useOrderContext()
  const lastVersionRef = useRef(state.version)

  const groupedTerms = groupBy(availableTerms, (term) => term.targetGroup)

  const onProceed = () => {
    const navigateToBookings = () => navigate('./bookings')
    if (lastVersionRef.current === state.version && state.bookings.length > 0) {
      navigateToBookings()
    } else {
      dispatch({ type: 'initBookingState' }, navigateToBookings)
    }
  }

  if (availableTerms.length === 0) {
    return <NoTermsAvailable />
  }

  const showDepositNotice = availableTerms.every(
    (term) => term.deposit < term.price
  )

  const navbarClasses =
    state.reservations.length > 0
      ? 'translate-y-0 opacity-100'
      : 'translate-y-full opacity-0'

  return (
    <>
      <MainHeading />
      <ExpiryNotice />
      <Content>
        {showDepositNotice && (
          <InfoNotice>
            <div className="flex items-start space-x-2">
              <InfoCircledIcon className="mt-[2px]" />
              <p>
                Du trenger kun å betale{' '}
                {formatCurrency(availableTerms[0].deposit)} / deltaker for å
                fullføre bookingen.
              </p>
            </div>
          </InfoNotice>
        )}
        {Object.entries(groupedTerms).map(([targetGroup, terms]) => (
          <div key={targetGroup} className="mb-6">
            <TargetGroupHeading targetGroup={targetGroup as TargetGroup} />

            <div className="flex flex-col gap-4">
              {terms.map((term) => (
                <NewOrderTermCard key={term.id} term={term}>
                  <ReserveTerm termId={term.id} />
                </NewOrderTermCard>
              ))}
            </div>
          </div>
        ))}

        <BottomNavbar className="justify-center" transition={navbarClasses}>
          <Button type="button" variant="main" size="main" onClick={onProceed}>
            Start booking
          </Button>
        </BottomNavbar>
      </Content>
    </>
  )
}
