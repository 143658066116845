import { Routes, Route } from 'react-router-dom'
import { Content, Layout } from '@/components/layout'
import { BookingRoute } from './bookings/booking'
import { OrderRoute } from './order'
import { CreateContactPerson } from './contact-person/create-contact-person'
import { EditCustomer } from './customer/edit-customer'
import { EditContactPerson } from './contact-person/edit-contact-person'

export const OrderRoutes = () => {
  return (
    <Layout>
      <Content>
        <Routes>
          <Route path="/bookings/:bookingId" element={<BookingRoute />} />
          <Route path="/edit-customer/:customerId" element={<EditCustomer />} />
          <Route
            path="/edit-contact-person/:contactPersonId"
            element={<EditContactPerson />}
          />
          <Route path="/new-contact-person" element={<CreateContactPerson />} />
          <Route path="/" element={<OrderRoute />} />
        </Routes>
      </Content>
    </Layout>
  )
}
