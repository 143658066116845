import { FormControl, FormItem, FormLabel } from './ui/form'
import { RadioGroup, RadioGroupItem } from './ui/radio-group'

type Props = {
  value: string
  onChange: (value: string) => void
}

export const GenderRadioGroup = ({ value, onChange }: Props) => {
  return (
    <RadioGroup
      onValueChange={onChange}
      value={value}
      className="flex flex-row gap-x-5"
    >
      <FormItem className="flex items-center space-x-3 space-y-0">
        <FormControl>
          <RadioGroupItem value="male" />
        </FormControl>
        <FormLabel className="font-normal text-base">Gutt</FormLabel>
      </FormItem>
      <FormItem className="flex items-center space-x-3 space-y-0">
        <FormControl>
          <RadioGroupItem value="female" />
        </FormControl>
        <FormLabel className="font-normal text-base">Jente</FormLabel>
      </FormItem>
      <FormItem className="flex items-center space-x-3 space-y-0">
        <FormControl>
          <RadioGroupItem value="other" />
        </FormControl>
        <FormLabel className="font-normal text-base">Annet</FormLabel>
      </FormItem>
    </RadioGroup>
  )
}
