import { ReactNode } from 'react'

type InfoNoticeProps = {
  children: ReactNode
}

export const InfoNotice = ({ children }: InfoNoticeProps) => {
  return (
    <div className="bg-light-blue-2 py-5 px-3 border-light-blue mb-4 text-start text-sm rounded-md">
      {children}
    </div>
  )
}
