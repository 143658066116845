import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { Content } from '@/components/layout'
import { BottomNavbar } from '@/components/bottom-navbar'
import { ContactsForm, FormValues } from './components/contacts-form'
import { useOrderContext } from './components/order-context'
import { ExpiryNotice } from './components/expiry-notice'

export const Contacts = () => {
  const { state, dispatch } = useOrderContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (state.bookings.length === 0) {
      navigate('..')
    }
  }, [])

  const onSubmit = async (input: FormValues) => {
    dispatch({ type: 'setCustomer', data: input.customer }, () => {
      navigate('../payment')
    })
  }

  return (
    <>
      <ExpiryNotice />
      <Content>
        <ContactsForm
          onSubmit={onSubmit}
          defaultValues={{ customer: state.customer }}
        />

        <BottomNavbar className="justify-between">
          <Button asChild variant="ghost">
            <Link to="../bookings">Gå tilbake</Link>
          </Button>

          <Button type="submit" form="contacts-form">
            Betaling
          </Button>
        </BottomNavbar>
      </Content>
    </>
  )
}
