import { intervalToDuration } from 'date-fns'
import { graphql } from '@/__generated__/gql'
import { BookingCardHeaderFragment } from '@/__generated__/gql/graphql'
import { formatDate } from '@/utils/format-date'
import { phrases } from '@/utils/phrases'
import { formatCurrency } from '@/utils/format-currency'
import { cn } from '@/utils/cn'
import { CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type Props = {
  booking: BookingCardHeaderFragment
}

export const cardHeaderDocument = graphql(`
  fragment BookingCardHeader on Booking {
    id
    number
    status
    price
    term {
      id
      name
      startsAt
      endsAt
    }
  }
`)

export const BookingCardHeader = ({ booking }: Props) => {
  const isCanceled = booking.status === 'canceled'
  const interval = intervalToDuration({
    start: new Date(booking.term.startsAt),
    end: new Date(booking.term.endsAt),
  })

  const numberOfDays =
    (interval.days ?? 0) + Math.ceil((interval.hours ?? 0) / 24) + 1

  return (
    <CardHeader>
      <CardTitle className={cn(isCanceled && 'line-through')}>
        <div className="flex justify-between font-medium">
          <span>{booking.term.name}</span>
          <span>{formatCurrency(booking.price)}</span>
        </div>
      </CardTitle>
      <CardDescription>
        <div className="flex justify-between mt-1">
          <span>
            {formatDate(booking.term.startsAt)} -{' '}
            {formatDate(booking.term.endsAt)}
          </span>
          <span>{numberOfDays} dager</span>
        </div>
        <div className="flex justify-between mt-1">
          <span>Status</span>
          <span>{phrases.bookingStatuses[booking.status]}</span>
        </div>
      </CardDescription>
    </CardHeader>
  )
}
