import { DeepPartial, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Checkbox } from '@/components/ui/checkbox'

type Props = {
  defaultValues?: DeepPartial<FormValues>
  onSubmit: (input: FormValues) => void | Promise<void>
}

const formSchema = z
  .object({
    customPostAddress: z.boolean(),
    firstName: z.string().min(2, 'Fornavn må fylles ut'),
    lastName: z.string().min(2, 'Etternavn må fylles ut'),
    email: z.string().email('E-post må fylles ut'),
    phone: z.string().min(8, 'Nummer må ha minst 8 tegn'),
    address: z.object({
      streetAddress: z.string().min(2, 'Gateadresse må fylles ut'),
      postalCode: z.string().min(4, 'Postnummer må fylles ut'),
      postalArea: z.string().min(2, 'Poststed må fylles ut'),
    }),
    postAddress: z.object({
      streetAddress: z.string(),
      postalCode: z.string(),
      postalArea: z.string(),
    }),
  })
  .superRefine((val, ctx) => {
    const { customPostAddress, postAddress } = val
    if (customPostAddress) {
      if (!postAddress.streetAddress || postAddress.streetAddress.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['customer', 'postAddress', 'streetAddress'],
          message: 'Gateadresse må fylles ut',
        })
      }
      if (!postAddress.postalCode || postAddress.postalCode.length < 4) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['customer', 'postAddress', 'postalCode'],
          message: 'Postnummer må fylles ut',
        })
      }
      if (!postAddress.postalArea || postAddress.postalArea.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['customer', 'postAddress', 'postalArea'],
          message: 'Poststed må fylles ut',
        })
      }
    }
  })

export type FormValues = z.infer<typeof formSchema>

export const EditCustomerForm = ({ onSubmit, defaultValues }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const customPostAddress = form.watch('customPostAddress')

  return (
    <Form {...form}>
      <form
        id="customer-form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <fieldset className="flex flex-col gap-5">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Fornavn</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Etternavn</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>E-post</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Telefon</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="address.streetAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Gateadresse</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="address.postalCode"
              render={({ field }) => (
                <FormItem className="basis-1/3">
                  <FormLabel required>Postnummer</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address.postalArea"
              render={({ field }) => (
                <FormItem className="basis-2/3">
                  <FormLabel required>Sted</FormLabel>
                  <FormControl>
                    <Input {...field} name="address.city" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="customPostAddress"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center space-x-3">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(value) => field.onChange(!!value)}
                    />
                  </FormControl>
                  <FormLabel className="font-normal text-base">
                    Legg til postadresse
                  </FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </fieldset>

        {customPostAddress && (
          <fieldset className="flex flex-col gap-5">
            <legend>Postadresse</legend>
            <FormField
              control={form.control}
              name="postAddress.streetAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Gateadresse</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-row gap-4">
              <FormField
                control={form.control}
                name="postAddress.postalCode"
                render={({ field }) => (
                  <FormItem className="basis-1/3">
                    <FormLabel required>Postnummer</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="postAddress.postalArea"
                render={({ field }) => (
                  <FormItem className="basis-2/3">
                    <FormLabel required>Sted</FormLabel>
                    <FormControl>
                      <Input {...field} name="postAddress.city" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </fieldset>
        )}
      </form>
    </Form>
  )
}
