import { ReactNode, Component, ErrorInfo } from 'react'
import { phrases } from '@/utils/phrases'
import { Content, Layout } from './layout'

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
  }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return (
        <Layout>
          <Content>
            <h1 className="text-2xl font-extrabold text-center mb-4">
              {phrases.error.title}
            </h1>
            <p className="text-center">{phrases.error.description}</p>
          </Content>
        </Layout>
      )
    }

    return this.props.children
  }
}
