import { useEffect, useRef } from 'react'
import { useToast } from './ui/use-toast'
import { ToastAction } from './ui/toast'

export function OutdatedVersionNotification({ open }: { open: boolean }) {
  const openedRef = useRef(false)
  const { toast } = useToast()

  useEffect(() => {
    if (!open || openedRef.current) return
    openedRef.current = true
    toast({
      title: 'Utdatert versjon',
      description: (
        <>
          Du bruker en utdatert versjon av nettsiden, vennligst oppdater siden
          for å unngå feilmeldinger.
        </>
      ),
      action: (
        <ToastAction
          altText="Oppdater"
          onClick={(e) => {
            e.preventDefault()
            window.location.reload()
          }}
        >
          Oppdater
        </ToastAction>
      ),
      duration: 60 * 60 * 1000,
    })
  }, [open])

  return null
}
