export const OrderHeader = () => {
  return (
    <h1 className="text-3xl font-bold text-center">
      Dine{' '}
      <span className="border-b-4 border-light-blue inline-block h-[2.05rem]">
        bookinger
      </span>
    </h1>
  )
}
