import { useEffect, useRef } from 'react'

export function useOnMount(fn: () => void | Promise<void>) {
  const didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      fn()
    }
  }, [])
}
