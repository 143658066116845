import { CombinedError } from 'urql'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { phrases } from '@/utils/phrases'
import { Alert, AlertDescription, AlertTitle } from './ui/alert'

type Props = {
  error: CombinedError
}

export const QueryError = ({ error }: Props) => {
  console.error(error)

  return (
    <Alert className="my-8" variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>{phrases.error.title}</AlertTitle>
      <AlertDescription>{phrases.error.description}</AlertDescription>
    </Alert>
  )
}
