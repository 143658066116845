import { graphql } from '@/__generated__/gql'

export const createPaymentIntentDocument = graphql(`
  mutation CreateOrderPaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      redirectUrl
      reference
    }
  }
`)
