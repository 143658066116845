import { TargetGroup } from '@/__generated__/gql/graphql'
import { cn } from '@/utils/cn'
import { phrases } from '@/utils/phrases'
import CompassIcon from '@/assets/compass.svg'
import BinocularsIcon from '@/assets/binoculars.svg'

type Props = {
  targetGroup: TargetGroup
  className?: string
}

export const TargetGroupHeading = ({ targetGroup, className }: Props) => {
  return (
    <div
      className={cn(
        'mb-4 text-center flex align-bottom justify-center space-x-1',
        className
      )}
    >
      {targetGroup === 'junior' ? (
        <img src={CompassIcon} alt="Compass" width={32} />
      ) : (
        <img src={BinocularsIcon} alt="Binoculars" width={32} />
      )}
      <div className="pt-0.5">
        <span className="text-xl font-bold border-b-4 border-light-blue inline-block h-[1.55rem]">
          {phrases.camps[targetGroup].name}
        </span>{' '}
        <span className="font-light">{phrases.camps[targetGroup].ages}</span>
      </div>
    </div>
  )
}
