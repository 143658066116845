import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { TargetGroup } from '@/__generated__/gql/graphql'
import { groupBy } from '@/utils/group-by'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { Button } from '@/components/ui/button'
import { Dd, Dl, Dt } from '@/components/ui/dl'
import { TargetGroupHeading } from '@/components/target-group-heading'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { BookingCardHeader } from './components/booking-card-header'
import { ContactSection } from './components/contact-section'
import { OrderHeader } from './components/order-header'

const orderDocument = graphql(`
  query Order($id: ID!, $signature: String!) {
    order(id: $id, signature: $signature) {
      id
      customer {
          ...Customer
        }
      contactPersons {
        ...ContactPerson
      }
      bookings {
        id
        firstName
        lastName
        status
        term {
          id
          targetGroup
        }
        ...BookingCardHeader
      }
    }
  }
`)

export const OrderRoute = () => {
  const params = useParams<'signature' | 'orderId'>()
  const [{ data, error, fetching }] = useQuery({
    query: orderDocument,
    variables: { id: params.orderId!, signature: params.signature! },
  })

  const order = data?.order
  const bookings = order?.bookings || []
  const bookingsByTargetGroup = groupBy(
    bookings,
    (booking) => booking.term.targetGroup
  )

  return (
    <>
      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {order && (
        <div className="flex flex-col gap-4">
          <OrderHeader />
          <ContactSection
            customer={order.customer}
            contactPersons={order.contactPersons}
          />
          {Object.entries(bookingsByTargetGroup).map(
            ([targetGroup, bookings]) => (
              <div key={targetGroup} className="flex flex-col gap-4 mb-3">
                <TargetGroupHeading
                  targetGroup={targetGroup as TargetGroup}
                  className="mb-0"
                />

                {bookings.map((booking) => (
                  <Card key={booking.id}>
                    <BookingCardHeader booking={booking} />
                    <CardContent>
                      <p className="mb-2 font-semibold">
                        Booking #{booking.number}
                      </p>
                      <Dl>
                        <Dt>Navn</Dt>
                        <Dd>
                          {booking.firstName} {booking.lastName}
                        </Dd>
                      </Dl>
                    </CardContent>
                    <CardFooter className="justify-end">
                      <Button asChild>
                        <Link to={`./bookings/${booking.id}`}>
                          Vis/rediger booking
                        </Link>
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            )
          )}
        </div>
      )}
    </>
  )
}
