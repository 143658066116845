import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

const isDateWithinRange = (dob: string, minDob: string, maxDob: string) => {
  const dobDate = new Date(dob)
  const minDate = new Date(minDob)
  const maxDate = new Date(maxDob)
  return dobDate >= minDate && dobDate <= maxDate
}

/**
 * Safari iOS gives a bad UX when using the date input, so we need to validate
 * the date manually while the value is changing.
 * Usage:
 * const { minDob, maxDob } = useDobValidator(form, term)
 */
export function useDobValidator<T extends UseFormReturn<any, any, undefined>>(
  form: T,
  term: { targetGroup: string; startsAt: string }
) {
  const ageConstraints =
    term.targetGroup === 'senior' ? { min: 15, max: 16 } : { min: 8, max: 14 }
  const year = new Date(term.startsAt).getFullYear()
  const maxDob = `${year - ageConstraints.min}-12-31`
  const minDob = `${year - ageConstraints.max}-01-01`
  const dobValue = form.watch('dob')

  useEffect(() => {
    if (dobValue && !isDateWithinRange(dobValue, minDob, maxDob)) {
      form.setError('dob', {
        type: 'manual',
        message: 'Fødselsdato er utenfor gyldig område',
      })
    } else {
      form.clearErrors('dob')
    }
  }, [dobValue, minDob, maxDob])

  return { minDob, maxDob }
}
