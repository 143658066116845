import {
  useContext,
  createContext,
  ReactNode,
  useReducer,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { OrderContextQuery, PaymentMethod } from '@/__generated__/gql/graphql'
import { FormValues as BookingsForm } from './bookings-form'
import { FormValues as ContactsForm } from './contacts-form'

type AvailableTerms = OrderContextQuery['webOrder']['availableTerms']

export type OrderContextProviderProps = {
  children: ReactNode
  availableTerms: AvailableTerms
  availablePaymentMethods: PaymentMethod[]
  canSetSweaterSize: boolean
  canSetCreditLimit: boolean
  initialState: { id: string } & Partial<State>
}

type State = {
  id: string
  paymentMethod: PaymentMethod
  reservations: {
    id: string
    termId: string
    accepted: boolean
    expiresAt: string
    deposit: number
  }[]
  bookings: BookingsForm['bookings']
  customer: ContactsForm['customer']
  termsAccepted: boolean
  version: number
}

type Action =
  | {
      type: 'setPaymentMethod'
      data: State['paymentMethod']
    }
  | {
      type: 'setReservations'
      data: State['reservations']
    }
  | {
      type: 'setBookings'
      data: State['bookings']
    }
  | {
      type: 'setCustomer'
      data: State['customer']
    }
  | {
      type: 'initBookingState'
    }
  | {
      type: 'setTermsAccepted'
      data: State['termsAccepted']
    }

const reducer = (state: State, action: Action): State => {
  const version = new Date().getTime()
  switch (action.type) {
    case 'setPaymentMethod':
      return {
        ...state,
        version,
        paymentMethod: action.data,
      }
    case 'setReservations':
      return {
        ...state,
        version,
        reservations: action.data.map((res) => ({
          id: res.id,
          termId: res.termId,
          accepted: res.accepted,
          expiresAt: res.expiresAt,
          deposit: res.deposit,
        })),
      }
    case 'setBookings':
      return {
        ...state,
        version,
        bookings: action.data,
      }
    case 'setCustomer':
      return {
        ...state,
        version,
        customer: action.data,
      }
    case 'initBookingState':
      return {
        ...state,
        version,
        bookings: state.reservations.map((res) => ({
          reservationId: res.id,
          termId: res.termId,
          firstName: '',
          lastName: '',
          dob: '',
          gender: undefined!,
          pastVisits: 0,
          friends: '',
          notes: '',
          sweaterSize: null,
          creditLimit: null,
          canSwim: undefined!,
          notBringingPhone: undefined!,
          legalGuardian: undefined!,
        })),
        customer: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: {
            streetAddress: '',
            postalCode: '',
            postalArea: '',
          },
          postAddress: {
            streetAddress: '',
            postalCode: '',
            postalArea: '',
          },
        },
      }
    case 'setTermsAccepted':
      return {
        ...state,
        version,
        termsAccepted: action.data,
      }
    default:
      throw new Error('Invalid action')
  }
}

type OrderContextType = {
  state: State
  dispatch: (action: Action, callback?: () => void) => void
  availableTerms: AvailableTerms
  availablePaymentMethods: PaymentMethod[]
  canSetSweaterSize: boolean
  canSetCreditLimit: boolean
}

const OrderContext = createContext<OrderContextType>(undefined!)

export function OrderContextProvider({
  availableTerms,
  availablePaymentMethods,
  canSetSweaterSize,
  canSetCreditLimit,
  initialState,
  children,
}: OrderContextProviderProps) {
  const callbackRef = useRef<any>()
  const [state, dispatch] = useReducer(reducer, {
    version: new Date().getTime(),
    paymentMethod: availablePaymentMethods[0],
    reservations: [],
    bookings: [],
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: {
        streetAddress: '',
        postalCode: '',
        postalArea: '',
      },
      postAddress: {
        streetAddress: '',
        postalCode: '',
        postalArea: '',
      },
    },
    termsAccepted: false,
    ...initialState,
  })

  useEffect(() => {
    callbackRef.current?.(state)
  }, [state])

  const customDispatch = useCallback(
    (action: Action, callback?: () => void) => {
      callbackRef.current = callback
      dispatch(action)
    },
    [dispatch]
  )

  return (
    <OrderContext.Provider
      value={{
        state,
        dispatch: customDispatch,
        availableTerms,
        availablePaymentMethods,
        canSetSweaterSize,
        canSetCreditLimit,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export function useOrderContext() {
  return useContext(OrderContext) as OrderContextType
}
