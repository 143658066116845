import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { toast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { BottomNavbar } from '@/components/bottom-navbar'
import { OrderHeader } from '../components/order-header'
import { ContactHeader } from '../components/contact-section-header'

const createContactPersonDocument = graphql(`
  mutation CreateContactPerson($input: ContactPersonInput!, $signature: String) {
    createContactPerson(input: $input, signature: $signature) {
      order {
        id
        contactPersons {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`)

const formSchema = z.object({
  firstName: z.string().min(2, 'Fornavn må fylles ut'),
  lastName: z.string().min(2, 'Etternavn må fylles ut'),
  email: z.string().email('E-post må fylles ut'),
  phone: z.string().min(8, 'Nummer må ha minst 8 tegn'),
})

export type FormValues = z.infer<typeof formSchema>

export const CreateContactPerson = () => {
  const params = useParams<'signature' | 'orderId'>()
  const navigate = useNavigate()
  const [{ fetching: submitting }, createContactPerson] = useMutation(
    createContactPersonDocument
  )

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { firstName: '', lastName: '', email: '', phone: '' },
  })
  const { handleSubmit } = form

  const onSubmit = async (formInput: FormValues) => {
    const input = {
      orderId: params.orderId as string,
      ...formInput,
    }
    const { error } = await createContactPerson({
      input,
      signature: params.signature,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kontaktperson er opprettet',
      variant: 'default',
    })
    navigate('../')
  }

  return (
    <div className="flex flex-col gap-4">
      <OrderHeader />
      <ContactHeader />
      <Form {...form}>
        <form
          id="contact-person-form"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <fieldset className="flex flex-col gap-5">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Fornavn</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Etternavn</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>E-post</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Telefon</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
        </form>
      </Form>
      <BottomNavbar className="justify-between">
        <>
          <Button variant="ghost" onClick={() => navigate('../')}>
            Avbryt
          </Button>
          <Button form="contact-person-form" disabled={submitting}>
            Lagre
          </Button>
        </>
      </BottomNavbar>
    </div>
  )
}
