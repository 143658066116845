import { useId } from 'react'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useOrderContext } from './order-context'

type Props = {
  termId: string
}

const createReservationsDocument = graphql(`
  mutation CreateReservations($input: CreateReservationsInput!) {
    createReservations(input: $input) {
      webOrder {
        id
        reservations {
          id
          termId
          accepted
          expiresAt
          deposit
        }
      }
    }
  }
`)

const cancelReservationsDocument = graphql(`
  mutation CancelReservations($input: CancelReservationsInput!) {
    cancelReservations(input: $input) {
      webOrder {
        id
        reservations {
          id
          termId
          accepted
          expiresAt
          deposit
        }
      }
    }
  }
`)

export const ReserveTerm = ({ termId }: Props) => {
  const id = useId()
  const { state, dispatch } = useOrderContext()
  const { toast } = useToast()
  const [{ fetching: creating }, createReservations] = useMutation(
    createReservationsDocument
  )
  const [{ fetching: canceling }, cancelReservations] = useMutation(
    cancelReservationsDocument
  )

  const fetching = creating || canceling
  const quantitySelected = state.reservations.filter(
    (r) => r.termId === termId
  ).length
  const isSelected = quantitySelected > 0

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const { data, error } = await createReservations({
      input: {
        orderId: state.id,
        termId,
        quantity: Number(formData.get('quantity')),
      },
    })
    if (error) {
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
    }
    if (data) {
      dispatch({
        type: 'setReservations',
        data: data.createReservations.webOrder.reservations.map((res) => ({
          ...res,
          expiresAt: res.expiresAt!,
        })),
      })
    }
  }

  const handleCancel = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    const { data, error } = await cancelReservations({
      input: {
        orderId: state.id,
        termId,
      },
    })
    if (error) {
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
    }
    if (data) {
      dispatch({
        type: 'setReservations',
        data: data.cancelReservations.webOrder.reservations.map((res) => ({
          ...res,
          expiresAt: res.expiresAt!,
        })),
      })
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex justify-between items-end">
        <div>
          <label htmlFor={`${id}-quantity`}>Antall deltakere</label>
          <Select
            name="quantity"
            disabled={isSelected}
            defaultValue={String(quantitySelected || 1)}
          >
            <SelectTrigger id={`${id}-quantity`}>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1</SelectItem>
              <SelectItem value="2">2</SelectItem>
              <SelectItem value="3">3</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          {isSelected && (
            <Button
              type="button"
              variant="secondary"
              className="w-full"
              onClick={handleCancel}
              disabled={fetching}
            >
              Fjern reservasjon
            </Button>
          )}

          {!isSelected && (
            <Button type="submit" className="w-full" disabled={fetching}>
              Velg
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}
