import Logo from '@/assets/oksnoen-logo.png'
import { cn } from '@/utils/cn'

type Props = {
  children: React.ReactNode
  className?: string
}

export const Layout = ({ children }: Props) => {
  return (
    <div>
      <header className="h-[82px] mb-2 flex items-center justify-center">
        <img src={Logo} alt="Oksnøen" width={58} />
      </header>
      {children}
    </div>
  )
}

export const Content = ({ children, className }: Props) => {
  return (
    <main className={cn('container max-w-md px-6 pb-20', className)}>
      {children}
    </main>
  )
}
