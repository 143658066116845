export const MainHeading = () => {
  return (
    <div className={'mb-4 text-center'}>
      <span className="text-[40px] font-bold ">
        Book{' '}
        <span className="border-b-4 border-light-blue inline-block h-[3.05rem]">
          leir
        </span>
      </span>
    </div>
  )
}
