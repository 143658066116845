import {
  Routes,
  Route,
  Navigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { useOnMount } from '@/hooks/use-on-mount'
import { Content, Layout } from '@/components/layout'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { useToast } from '@/components/ui/use-toast'
import {
  OrderContextProvider,
  OrderContextProviderProps,
} from './components/order-context'
import { Terms } from './terms'
import { Bookings } from './bookings'
import { Contacts } from './contacts'
import { Payment } from './payment'

const orderContextDocument = graphql(`
  query OrderContext($orderId: ID!) {
    webOrder(id: $orderId) {
      id
      verifyOrderId
      canSetSweaterSize
      canSetCreditLimit
      availablePaymentMethods
      availableTerms {
        id
        targetGroup
        deposit
        ...NewOrderTermCard
      }
      reservations {
        id
        termId
        accepted
        expiresAt
        deposit
      }
      draftOrderState
    }
  }
`)

export const NewOrderRoute = () => {
  const params = useParams<'orderId'>()
  const [searchParams] = useSearchParams()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: orderContextDocument,
    variables: { orderId: params.orderId! },
  })

  useOnMount(() => {
    if (searchParams.get('payment_error')) {
      toast({
        title: phrases.error.title,
        description: 'Betaling mislyktes. Vennligst prøv igjen.',
        variant: 'destructive',
      })
    }
  })

  // Go back and generate a new order ID if the current one is invalid,
  // e.g. it already exists
  const orderIdIsValid = data ? data.webOrder.verifyOrderId : undefined
  if (orderIdIsValid === false) {
    return <Navigate to=".." />
  }

  const availableTerms = data?.webOrder.availableTerms || []
  const availablePaymentMethods = data?.webOrder.availablePaymentMethods || []
  const reservations = (data?.webOrder.reservations || []).map((res) => ({
    ...res,
    expiresAt: res.expiresAt!,
  }))
  const draftOrderState = data?.webOrder.draftOrderState
    ? JSON.parse(data?.webOrder.draftOrderState)
    : null

  const initialState: OrderContextProviderProps['initialState'] = {
    id: params.orderId!,
    reservations,
  }
  if (draftOrderState?.bookings) {
    initialState.bookings = draftOrderState.bookings
  }
  if (draftOrderState?.customer) {
    initialState.customer = draftOrderState.customer
  }

  return (
    <Layout>
      {fetching && <Loading />}
      {error && (
        <Content>
          <QueryError error={error} />
        </Content>
      )}

      {!fetching && !error && (
        <OrderContextProvider
          availableTerms={availableTerms}
          availablePaymentMethods={availablePaymentMethods}
          canSetCreditLimit={!!data?.webOrder.canSetCreditLimit}
          canSetSweaterSize={!!data?.webOrder.canSetSweaterSize}
          initialState={initialState}
        >
          <Routes>
            <Route path="/" element={<Terms />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="*" element={<Navigate to="." />} />
          </Routes>
        </OrderContextProvider>
      )}
    </Layout>
  )
}
