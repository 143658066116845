export const phrases = {
  error: {
    title: 'Whoops, noe gikk galt!',
    description:
      'En uventet feil har oppstått. Vennligst last siden på nytt og prøv igjen.',
  },
  orderNotFound: 'Ordren ble ikke funnet. Vennligst prøv på nytt.',
  camps: {
    junior: {
      name: 'Juniorleir',
      ages: '8-14 år',
    },
    senior: {
      name: 'Seniorleir',
      ages: '15-16 år',
    },
  },
  genders: {
    male: 'Gutt',
    female: 'Jente',
    other: 'Annet',
  },
  bookingStatuses: {
    pending: 'Venteliste',
    accepted: 'Fått plass',
    canceled: 'Kansellert',
  },
}
