import { config } from '@/config'

export const formatCurrency = (amount: number) => {
  return Intl.NumberFormat(config.targetLocale, {
    currency: 'NOK',
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)
}
