import { useEffect, useState } from 'react'
import { useOrderContext } from './order-context'

export const ExpiryNotice = () => {
  const { state } = useOrderContext()
  const expiresAts = state.reservations.map((r) =>
    new Date(r.expiresAt).getTime()
  )
  const expiresAt =
    expiresAts.length > 0 ? new Date(Math.max(...expiresAts)) : null

  if (!expiresAt) return null

  return <Countdown expiresAt={expiresAt} />
}

const Countdown = ({ expiresAt }: { expiresAt: Date }) => {
  const calcTimeLeft = (now = new Date()) => {
    const difference = expiresAt.getTime() - now.getTime()
    return {
      minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60)),
      seconds: Math.max(0, Math.floor((difference / 1000) % 60)),
    }
  }

  const [timeLeft, setTimeLeft] = useState(calcTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calcTimeLeft()
      if (newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
        clearInterval(timer)
      }
      setTimeLeft(newTimeLeft)
    }, 1000)
    return () => clearInterval(timer)
  }, [expiresAt])

  if (timeLeft.minutes === 0 && timeLeft.seconds === 0) {
    return null
  }

  return (
    <div className="bg-green-200 p-2 mb-4 text-center text-sm sticky top-0">
      Fullfør innen{' '}
      <span className="font-semibold">
        {String(timeLeft.minutes).padStart(2, '0')}:
        {String(timeLeft.seconds).padStart(2, '0')}
      </span>{' '}
      min for å beholde plassen i køen
    </div>
  )
}
