import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type DefaultValues = { [k: string]: string }

export function useQueryParams(defaultValues?: DefaultValues) {
  const [params, setParams] = useSearchParams()
  const queryParams = Array.from(params.entries()).reduce((acc, item) => {
    return Object.assign(acc, { [item[0]]: item[1] })
  }, {})

  const setQueryParams = useCallback(
    (value: (prev: DefaultValues) => { [k: string]: string | undefined }) => {
      const v = value(queryParams)
      // Remove undefined values
      for (const key of Object.keys(v)) {
        if (v[key] === undefined) {
          delete v[key]
        }
      }
      setParams(v as { [k: string]: string })
    },
    [queryParams, setParams]
  )

  return [{ ...defaultValues, ...queryParams }, setQueryParams] as [
    { [k: string]: string },
    typeof setQueryParams,
  ]
}
