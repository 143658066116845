const Dl = ({ children }: { children?: React.ReactNode }) => {
  return <dl>{children}</dl>
}
Dl.displayName = 'Dl'

const Dt = ({ children }: { children?: React.ReactNode }) => {
  return <dt className="font-medium leading-6 text-gray-900">{children}</dt>
}
Dt.displayName = 'Dt'

const Dd = ({ children }: { children?: React.ReactNode }) => {
  return (
    <dd className="mb-2 last-of-type:mb-0 leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
      {children}
    </dd>
  )
}
Dd.displayName = 'Dd'

export { Dl, Dt, Dd }
