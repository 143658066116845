import { Link } from 'react-router-dom'
import {
  CustomerFragment,
  ContactPersonFragment,
} from '@/__generated__/gql/graphql'
import { PlusIcon } from '@radix-ui/react-icons'
import { CustomerCard } from './customer-card'
import { Accordion } from '@/components/ui/accordion'
import { ContactPersonCard } from './contact-person-card'
import { ContactHeader } from './contact-section-header'

type Props = {
  customer: CustomerFragment
  contactPersons: ContactPersonFragment[]
}

export const ContactSection = ({ customer, contactPersons }: Props) => {
  return (
    <div className="flex-column justify-center mb-3">
      <ContactHeader />
      <Accordion collapsible type="single">
        <CustomerCard customer={customer} />
        <div className="mt-4">
          {contactPersons.length > 0 ? (
            contactPersons.map((contactPerson) => (
              <ContactPersonCard
                key={contactPerson.id}
                contactPerson={contactPerson}
              />
            ))
          ) : (
            <Link
              to="./new-contact-person"
              className="flex justify-start items-center text-link-blue font-semibold"
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              Legg til kontaktperson
            </Link>
          )}
        </div>
      </Accordion>
    </div>
  )
}
