import { Link } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { ContactPersonFragment } from '@/__generated__/gql/graphql'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Dd, Dl, Dt } from '@/components/ui/dl'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { DeleteContactPersonButton } from './delete-contact-person-button'

export const contactPersonDocument = graphql(`
  fragment ContactPerson on ContactPerson {
    id
    firstName
    lastName
    email
    phone
  }
`)

export const ContactPersonCard = ({
  contactPerson,
}: { contactPerson: ContactPersonFragment }) => {
  return (
    <Card>
      <CardContent className="pb-2">
        <AccordionItem value="contact-person">
          <AccordionTrigger className="text-base">
            {`${contactPerson.firstName} ${contactPerson.lastName}`}
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col gap-4">
              <Dl>
                <Dt>Telefon</Dt>
                <Dd>{contactPerson.phone}</Dd>
              </Dl>
              <Dl>
                <Dt>Epost</Dt>
                <Dd>{contactPerson.email}</Dd>
              </Dl>
            </div>
          </AccordionContent>
        </AccordionItem>
      </CardContent>
      <CardFooter className="justify-between">
        <DeleteContactPersonButton contactPersonId={contactPerson.id} />
        <Link
          to={`./edit-contact-person/${contactPerson.id}`}
          className="flex justify-start items-center font-semibold text-sm"
        >
          <Pencil1Icon className="w-6 h-6 mr-2" />
          Rediger
        </Link>
      </CardFooter>
    </Card>
  )
}
