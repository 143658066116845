import { graphql } from '@/__generated__/gql'
import { TermCardFragment } from '@/__generated__/gql/graphql'
import { TermCard } from '@/components/term-card'
import { useOrderContext } from './order-context'

type Props = {
  term: TermCardFragment
  children?: React.ReactNode
  className?: string
}

export const termCardDocument = graphql(`
  fragment NewOrderTermCard on Term {
    id
    ...TermCard
  }
`)

export const NewOrderTermCard = ({ term, ...rest }: Props) => {
  const { state } = useOrderContext()

  const reservations = state.reservations.filter((r) => r.termId === term.id)
  const isSelected = reservations.length > 0

  const isAccepted =
    isSelected &&
    reservations.every((r) => r.accepted && new Date(r.expiresAt) > new Date())

  const isWaitingList = isSelected && reservations.every((r) => !r.accepted)

  return (
    <TermCard
      term={term}
      isAccepted={isAccepted}
      isWaitingList={isWaitingList}
      {...rest}
    />
  )
}
