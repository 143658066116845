import { PaymentMethod } from '@/__generated__/gql/graphql'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs'

type Props = {
  availablePaymentMethods: PaymentMethod[]
  paymentMethod: PaymentMethod
  setPaymentMethod: (method: PaymentMethod) => void
  showWaitinglistNotice?: boolean
}

export const PaymentElement = ({
  availablePaymentMethods,
  paymentMethod,
  setPaymentMethod,
  showWaitinglistNotice = false,
}: Props) => {
  const paymentMethods = {
    vipps: {
      name: 'Vipps',
      Component: (
        <>
          Du blir videresendt til Vipps for å fullføre betalingen. Vennligst
          ikke lukk Vipps-appen før bestillingen er fullført.
          {showWaitinglistNotice && (
            <p className="mt-2 text-xs text-[#6d6e78]">
              Totalbeløpet reserveres umiddelbart, men belastes først når
              bookinger er bekreftet.
            </p>
          )}
        </>
      ),
    },
    card: {
      name: 'Kort',
      Component: (
        <>
          Du blir videresendt til betalingsleverandør for å fullføre betalingen.
          Vennligst ikke lukk den nye siden før bestillingen er fullført.
          {showWaitinglistNotice && (
            <p className="mt-2 text-xs text-[#6d6e78]">
              Totalbeløpet reserveres umiddelbart, men belastes først når
              bookinger er bekreftet.
            </p>
          )}
        </>
      ),
    },
    none: {
      name: 'Ingen',
      Component: <>Som administrator kan du fullføre uten forhåndsbetaling.</>,
    },
    invoice: {
      name: null,
      Component: null,
    },
  }

  return (
    <Tabs
      className="w-full"
      value={paymentMethod}
      onValueChange={(v) => setPaymentMethod(v as PaymentMethod)}
    >
      <TabsList>
        {availablePaymentMethods.map((method) => (
          <TabsTrigger key={method} value={method}>
            {paymentMethods[method].name}
          </TabsTrigger>
        ))}
      </TabsList>
      {availablePaymentMethods.map((method) => (
        <TabsContent key={method} value={method}>
          {paymentMethods[method].Component}
        </TabsContent>
      ))}
    </Tabs>
  )
}
